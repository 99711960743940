import React from "react";
import "style.css";
import "tailwindcss/dist/base.css";
// import "./index.css"
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/hero/BackgroundAsImageWithCenteredContent";
import Intro from "components/features/TwoColSingleFeatureWithStats.js";
// import Pros from "components/features/DashedBorderSixFeatures";
import Pros from "components/features/ThreeColWithSideImage";
import Portfolio from "components/cards/TabCardGrid";
// import FAQ from "components/faqs/SingleCol";
// import Footer from "components/footers/MiniCenteredFooter";
import Industry from "./components/features/VerticalWithAlternateImageAndText";
import Address from "./components/features/TwoColWithSteps";
import Footer from "./components/footers/FiveColumnDark";


function App() {
    return (
        <AnimationRevealPage>
            <Header/>
            <Intro/>
            <Industry/>
            <Pros/>
            <Portfolio/>
            <Address/>
            <Footer/>
        </AnimationRevealPage>
    );
}

export default App;
