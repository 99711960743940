import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LogoImage from "images/logo.svg";
import QRCode from "images/QRcode.jpeg"

const Container_footer = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-2 sm:px-8 `;
const Content = tw.div`max-w-screen-2xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm sm:text-base font-medium `;
const LinkListNav = tw.ul`mt-4 text-sm sm:text-base font-medium grid grid-cols-2 gap-2`;
const Claim = tw.p`mt-4 text-sm sm:text-base  font-medium`;
// const LinkListItem = tw.li`mt-3`;
const LinkListItem = styled.li`
  ${tw`mt-0`}
`;
const LinkListContact = styled.li`
  ${tw`mt-3 flex flex-col sm:grid sm:grid-cols-3 sm:gap-0`}
`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;
const LinkMail = tw.a`border-b-2 border-transparent text-primary-500 hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-12`;
const QRCodeImg = tw.img`w-32 m-auto sm:m-0`;
const LogoText = tw.h5`ml-2 text-xl font-bold`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
    return (
        <Container_footer>
            <Content>
                <FiveColumns>
                    <Column>
                        <ColumnHeading>网站导航</ColumnHeading>
                        <LinkListNav>
                            <LinkListItem>
                                <Link href="#a_about">关于我们</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="#a_pros">专业能力</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="#a_focus">关注赛道</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="#a_portfolio">投资组合</Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link href="#a_contact">联系我们</Link>
                            </LinkListItem>
                        </LinkListNav>
                    </Column>
                    <Column>
                        <ColumnHeading>联系我们</ColumnHeading>
                        <LinkList>
                            <LinkListContact>
                                <span>投资人接洽</span>
                                <LinkMail href="mailto:support@servana.com">service@dshccapital.com</LinkMail>
                            </LinkListContact>
                            <LinkListContact>
                                <span>BP 投递</span>
                                <LinkMail href="mailto:support@servana.com">bp@dshccapital.com</LinkMail>
                            </LinkListContact>
                            <LinkListContact>
                                <span>业务合作</span>
                                <LinkMail href="mailto:support@servana.com">info@dshccapital.com</LinkMail>
                            </LinkListContact>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>关注我们</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <QRCodeImg src={QRCode}/>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>免责声明</ColumnHeading>
                        <Claim>
                            本网站所发布的信息仅供参考，本公司不保证该等信息的真实性、准确性、完整性，该等信息不构成本公司的任何推荐、要约或投资建议。本公司对本网站及其内容享有最终解释权。
                        </Claim>
                    </Column>
                </FiveColumns>
                <Divider/>
                <CopyrightAndCompanyInfoRow>
                    <CopyrightNotice>
                        &copy;2017-2023 鼎盛合创投资管理（北京）有限公司 版权所有
                    </CopyrightNotice>
                    <LogoContainer>
                        <LogoImg src={LogoImage}/>
                        {/*<LogoText>鼎盛合创</LogoText>*/}
                    </LogoContainer>
                    <CompanyInfo> <a href="https://beian.miit.gov.cn/">备案号：京ICP备2021030304号-1</a></CompanyInfo>
                </CopyrightAndCompanyInfoRow>
            </Content>
        </Container_footer>
    )
        ;
};
