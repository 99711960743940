import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {SectionHeading,} from "components/misc/Headings.js";
import {SectionDescription} from "components/misc/Typography.js";

import {ReactComponent as SvgDecoratorBlob3} from "images/svg-decorator-blob-3.svg";

// react icons
import {BiAtom, BiBell, BiExit, BsShieldCheck, FiFilter, FiSearch} from "react-icons/all";


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto py-20 md:py-24`}
`;
// const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-primary-500`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
    // ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;
const CardIcons = styled.span`
  ${tw`text-xl sm:text-2xl text-primary-700`}
`
const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
                    cards = null,
                    heading = "专业能力",
                    subheading = "Features",
                    description = "",
                }) => {
    /*
     * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  If a key for a particular card is not provided, a default value is used
     */

    const defaultCards = [
        {
            // imageSrc: CustomizeIconImage,
            imageSrc: <FiFilter/>,
            title: "优质项目资源",
            description:
                "和国内多所外顶级高等院校和科研院所、行业顶级科学家创业团队的多年合作经历，以及投资行业的丰富资源，在每年上百个过手项目中筛选核心赛道最具价值、风险收益比最佳中早期项目作为我们的股权投资标的。 ",
        },
        {
            // imageSrc: SupportIconImage,
            imageSrc: <FiSearch/>,
            title: "专业尽职调查",
            description:
                "通过专业的商业、财务、法务、以及技术专家研判，协同产业链上下游资源的协同论证，并明确退出路径的前提下，完成尽职调查，对投资者的信任赋予最大的勤勉尽责。",
        },
        {
            // imageSrc: ShieldIconImage,
            imageSrc: <BsShieldCheck/>,
            title: "风控合规流程",
            description:
                "在基金组建、投资、退出过程中，严格按照现行法律法规进行操作，严谨遵守基金合同的约定投资方式、投资估值，以及投资流程，全程合规风控，保证基金募投管退的顺利运作。",
        },
        {
            // imageSrc: ReliableIconImage,
            imageSrc: <BiAtom/>,
            title: "产业赋能协同",
            description:
                "对已投标的项目进行产业赋能，通过对接市场、技术、人才、政策、后续融资等方面的资源，全面赋能已投标的快速成长。",
        },
        {
            // imageSrc: FastIconImage,
            imageSrc: <BiBell/>,
            title: "基金投后管理",
            description:
                "在管基金进行季度信息披露，其中包括标的公司的财务状况、商业进展、融资及IPO进展，产品研发进度，商业化进度等。",
        },
        {
            // imageSrc: SimpleIconImage,
            imageSrc: <BiExit/>,
            title: "多元退出方案",
            description:
                "通过对资本市场的深刻理解和金融市场资源，对于不同的投资组合所处的不同细分行业和不同阶段，采取不同的证券化退出方案，如科创板、上交所、港股、美股SPAC等。",
        },
    ];

    if (!cards) cards = defaultCards;

    return (
        <Container id="a_pros">
            <ThreeColumnContainer>
                {/* {subheading && <Subheading>{subheading}</Subheading>} */}
                <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
                <VerticalSpacer/>
                {cards.map((card, i) => (
                    <Column key={i}>
                        <Card>
              <span className="imageContainer">
                {/*<img src={card.imageSrc || defaultCardImage} alt="" />*/}
                  <CardIcons>
                {card.imageSrc}
                  </CardIcons>
              </span>
                            <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                      "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
            <DecoratorBlob/>
        </Container>
    );
};
