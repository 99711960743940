import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern} from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
                    subheading = "",
                    heading = (
                        <>
                            <span tw="text-primary-500">联系我们</span>
                        </>
                    ),
                    imageSrc = TeamIllustrationSrc,
                    imageRounded = true,
                    imageBorder = false,
                    imageShadow = false,
                    imageDecoratorBlob = false,
                    textOnLeft = true,
                    steps = null,
                    decoratorBlobCss = null,
                }) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    const defaultSteps = [
        {
            heading: "北京（总部）",
            description: "北京市海淀区羊坊店博望园10号工信智创大楼2026"
        },
        {
            heading: "深圳",
            description: "广东省深圳市南山区豪威科技大厦2201"
        }
    ];

    if (!steps) steps = defaultSteps;

    return (
        <Container id={'a_contact'}>
            <TwoColumn>
                <ImageColumn>
                    {/*https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8b2ZmaWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60*/}
                    <Image src={'static/img/bg_address.jpeg'} imageBorder={imageBorder} imageShadow={imageShadow}
                           imageRounded={imageRounded}/>
                    {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss}/>}
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Steps>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                    <StepText>
                                        <StepHeading>{step.heading}</StepHeading>
                                        <StepDescription>{step.description}</StepDescription>
                                    </StepText>
                                </Step>
                            ))}
                        </Steps>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
