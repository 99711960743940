import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";
import {SectionHeading as HeadingTitle} from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;


const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100 text-lg sm:text-2xl`;
const Title = tw.h4`font-bold text-primary-500 text-2xl sm:text-3xl`;
const Description = tw.p`mt-2 leading-loose text-base sm:text-lg`;
const Description_type = tw.li`pl-2 mt-2  leading-loose text-base sm:text-lg`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
    const cards = [
        {
            imageSrc:
                // "https://images.unsplash.com/photo-1579803080319-43097a84f424?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGNoaXB8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=100",
            "static/img/bg_industry_semi.jpeg",
            subtitle: "技术资本密集 把握趋势演进",
            title: "电子科技",
            description:"无论是集成电路的高端制程生态，还是半导体材料和设备的精尖门槛，抑或是通信通讯、空天导航、国防军工，电子科技是国民经济和日常生活的血液，也是我国最重视的大类产业，是国家综合竞争力有力体现。我们关注电子科技的整体演进趋势，对于5G、区块链、智能制造、新材料等软件和硬件结合的创新方向，我们持续重点投资布局。",
            focus :"主要关注细分行业：",
            types:
                [
                    {industry: "集成电路半导体"},
                    {industry: "5G应用区块链"},
                    {industry:"人工智能VR/AR/MR"},
                    {industry:"智能制造新材料"},
                    {industry:"国产软件数字经济"}
                ],
            // description: ["集成电路半导体","5G应用区块链"],
            url: "https://timerse.com"
        },

        {
            imageSrc:
                "static/img/bg_industry_pharmacy.jpeg",
            // https://images.unsplash.com/photo-1633167606207-d840b5070fc2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3000&q=80
            subtitle: "赛道坡长雪厚 专注真实创新",
            title: "医疗大健康",
            description:"医疗大健康作为人口老龄化和人均收入提升的黄金赛道，在经历了集采等政策演变后，对投资也提出了更高的要求。" +
                "我们关注具有较大临床价值的行业真实创新，在生物医药方面，关注新机理、新靶点、新结构，而不是追随国内外已有的技术路线。" +
                "在医疗器械方面，我们关注国产替代红利和核心高值器件的原研项目。" +
                "在大健康方面，我们关注资本效率和资产利用率，希望用我们不断积累的专业能力，为投资人带来良好回报，并为健康中国贡献力量。",
            focus :"主要关注细分行业：",
            types:
                [
                    {industry: "医疗服务健康管理"},
                    {industry: "创新器械医疗智能"},
                    {industry: "生物医药基因技术"},
                    {industry: "医美保健"}
                ],
            url: "https://timerse.com"
        }
    ];

    return (
        <Container id={'a_focus'}>
            <SingleColumn>
                <HeadingInfoContainer>
                    <HeadingTitle>关注赛道</HeadingTitle>
                    {/*<HeadingDescription>*/}
                    {/*  Here are some of the most popular events in New York City curated by professionals.*/}
                    {/*</HeadingDescription>*/}
                </HeadingInfoContainer>

                <Content>
                    {cards.map((card, i) => (
                        <Card key={i} reversed={i % 2 === 1}>
                            <Image imageSrc={card.imageSrc}/>
                            <Details>
                                <Subtitle>{card.subtitle}</Subtitle>
                                <Title>{card.title}</Title>
                                {/*<Description>{card.description[0].xf}</Description>*/}
                                <Description>{card.description}</Description>
                                <Description>{card.focus}</Description>
                                {card.types.map((type,i) => <Description_type key={i}>{type.industry}</Description_type>)}
                                {/*{console.log('Card', card.description)}*/}
                                {/*<Description>{card.description.map((d)=>({d}))}</Description>*/}
                                {/*<Link href={card.url}>See Event Details</Link>*/}
                            </Details>
                        </Card>
                    ))}
                </Content>
            </SingleColumn>
            <SvgDotPattern1/>
            <SvgDotPattern2/>
            <SvgDotPattern3/>
            <SvgDotPattern4/>
        </Container>
    );
};
