import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)`text-primary-500`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0 z-50`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  // ${props => css`background: url("${props.imageSrc}"); 
  //   //background-size: 25% auto;
  //   //background-repeat: no-repeat;
  //   //background-size: 200px auto;
  //   background-size: 50px 100px;
  // `}
  ${tw`h-56 xl:h-64 bg-center bg-contain bg-no-repeat relative rounded-t`}
  ${'' /* 此前为 bg-cover,无bg-no-repeat */}
`;

const ImageContainer = styled.div`
  ${props => css`background: url("${props.imageSrc}"); 
    //background-size: 25% auto;
    //background-repeat: no-repeat;
    //background-size: 200px auto;
    background-size: 50px 100px;
  `}
  ${tw`h-56 xl:h-64 bg-center bg-contain bg-no-repeat relative rounded-t mx-8`}
  ${'' /* 此前为 bg-cover,无bg-no-repeat */}
`;


// const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
// const CardRating = styled.div`
//   ${tw`mr-1 text-sm font-bold flex items-end`}
//   svg {
//     ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
//   }
// `;

const CardHoverOverlay = styled(motion.div)`
  //background-color: rgba(255, 255, 255, 0.91);
  background-color: rgba(17, 76, 194);
  ${'' /* background-color: rgba(184, 184, 184, 0.2); */}
  ${'' /* 更改了hover后的字体颜色 */}
  ${tw`absolute inset-0 flex justify-center items-center font-medium text-gray-300 px-6`};
  ${'' /* ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}; */}
`;
// const CardButton = tw(PrimaryButtonBase)`text-sm`;

// const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
// const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "投资组合",
  tabs = {
    全部:[
      {
        imageSrc:
            "https://www.montage-tech.com/themes/custom/montage2/logo_cn.svg",
        title: "澜起科技(688008.SH)",
        content: "国际领先的数据处理及互连芯片设计公司",
        des:"澜起科技是国际领先的数据处理及互连芯片设计公司，为科创板首批上市企业。",
        url: "https://www.montage-tech.com/cn/home"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThKkTJ.png",
        title: "佳驰科技",
        content: "国内知名的电磁功能材料与结构提供商",
        des:"公司专注于电磁辐射功能材料与结构的设计、测试、分析及制造。2018年获国家科技进步二等奖、2017年荣获四川省科学技术进步一等奖。",
        url: "http://www.cdjc.com.cn/"
      },
      {
        imageSrc:
            "https://www.aubo-robotics.cn/assets/aubo/img/gsIcon.png",
        title: "遨博机器人",
        content: "协作机器人整体解决方案提供商",
        des:"遨博机器人为协作机器人整体解决方案提供商，首席科学家为倪光南院士，其具有核心知识产权的协作机器人产品，已实现核心部件国产化，打破了国外长期垄断。",
        url: "https://www.aubo-robotics.cn/"
      },
      {
        imageSrc:
            "https://www.leezon.net/uploads/news/20200301191002674600.jpg",
        title: "木蚁机器人",
        content: "智慧物流仓储自主移动机器人专家",
        des:"木蚁机器人进行自主移动机器人的研发和生产，推出人机交互、多机器人调度等工业级闭环场景应用解决方案，助力智慧物流仓储。",
        url: "http://www.mooe-robot.com/#"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAM3F.png",
        title: "苍穹数码",
        content: "地理信息全产业链服务提供商",
        des:"长期专注于国产自主可控地理信息平台的研制与应用开发，业内率先打通地理信息全产业链的平台级产品、技术与服务提供商。",
        url: "http://www.kqgeo.com/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAQc4.jpg",
        title: "启元气体",
        content: "半导体级单体稀有气体提供商",
        des:"公司依托技术积累开发出了一系列国产化电子级气体设备，拥有具备自主知识产权的稀有气体精炼设备氪氙及氖氦设备。",
        url: "http://shqiyuangas.com/"
      },
      {
        imageSrc:
        // update logo url
            "https://cv2x.com.cn/static/img/logo.af4ca32.png",
        title: "车路通",
        content: "智慧交通及智能网联汽车解决方案提供商",
        des:"深入挖掘车端客户和路端客户的应用需求，打造基于5G、V2X、高精定位、MEC边缘运算等技术为核心的系统化解决方案。",
        url: "https://cv2x.com.cn/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAnhT.png",
        title: "科望生物",
        content: "专注于肿瘤免疫治疗的生物医药公司",
        des:"科望生物医药科技有限公司是一家专注于肿瘤免疫治疗的生物医药公司，管线覆盖广泛，重点布局于固有免疫和肿瘤微环境调控。",
        url: "https://www.elpiscience.com/cn/"
      },
      {
        imageSrc:
            "https://www.chima.org.cn/Json/Commons/ImgUrl?url=http://mmbiz.qpic.cn/mmbiz_png/sRFbqwsjVziaibyngQMUtPHTtbzTqFq3dO8xHSts6mZGytVGavg598ND9CrsicLibQhict4ar9jwDMRN7xOicw5hv8RA/0?wx_fmt=png",
        title: "联新医疗",
        content: "智慧医疗整体解决方案提供商",
        des:"深圳市联新移动医疗科技有限公司是一家贴紧临床，为医生、护士、患者和医院管理提供智能化产品和服务，助力建设智慧医院。",
        url: "http://www.lachesis-mh.cn/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAGH1.jpg",
        title: "中慧元通",
        content: "人用创新型疫苗的供应商",
        des:"中慧元通致力于人用创新型疫苗的研发、注册申报及产业化生产和销售，瞄准国际水准，替代进口，实现出口。",
        url: "http://www.abbbio.com/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThA8BR.jpg",
        title: "世纪康泰",
        content: "人工晶状体研发及生产商",
        des:"世纪康泰为集研发、生产和销售为一体的三类医疗器械生产企业，公司主要产品为眼科材料人工晶体系列产品及生物材料胶原蛋白海绵系列产品。",
        url: "http://www.tjsjkt.com/tjsjkt/"
      },
      {
        imageSrc:
            "https://s1.ax1x.com/2022/03/26/qdABOf.png",
        title: "爱姆欧",
        content: "集成电路MO源生产商",
        des:"爱姆欧专业生产拥有自主知识产权的高纯金属有机化合物（MO源）以及聚烯烃/合成橡胶行业专用烷基铝催化剂，打破了国外公司几十年来在该领域的垄断。",
        url: "http://www.js-mo.com"
      },
      {
        imageSrc:
            "http://www.kairuibiotech.com/uploadfile/20190311/15522594594759626.png",
        title: "恺瑞生物",
        content: "无血清细胞培养基提供商",
        des:"恺瑞生物以无血清细胞培养技术为主导，重点解决蛋白药及疫苗生产、干细胞及免疫细胞治疗以及新药研发等方面的细胞培养问题，协助企业和研发机构提高效率、降低风险及节省费用。 ",
        url: "http://www.kairuibiotech.com/"
      },
      {
        imageSrc:
            // "static/img/logo_wpkj.png",
            'static/img/logo_wpkj.png',
        title: "威频科技",
        content: "高端通用测量仪器仪表研发",
        des:"威频科技核心产品为高端通用射频测试仪表系列，包括模拟信号源、矢量信号源、捷变频信号源、频谱仪等系列化具有核心自主知识产权的通用测试分析仪器仪表，仪表产品广泛应用于民用通信、汽车电子、半导体及航空航天领域。",
        url: "http://www.wpmicrowave.com/"
      },
      {
        imageSrc:
            "static/img/logo_clxny.png",
        title: "纯锂新能源",
        content: "全固态锂电池供应商",
        des:"纯锂新能源已经完成聚合物全固态锂电池的技术论证和量产工艺，具备全固态锂电池量产技术和能力，目前专注于储能和动力领域。",
        // url: "#"
      },
      {
        imageSrc:
            "http://splchip.com/themes/simplebootx/Public/images/logo.jpg",
        title: "智微电子",
        content: "提供物联网集成电路解决方案",
        des:"智微电子致力于物联网领域集成电路的设计、软件开发和整体解决方案提供，产品广泛应用在智慧能源、智能家居、工业物联网等行业。",
        url: "http://splchip.com/"
      },
      {
        imageSrc:
            "http://yishunlvse.com/public/default/yishun/images/logo.png",
        title: "易舜绿色",
        content: '提供危险废弃物的"零排放"解决方案',
        des:'易舜绿色从事多功能直流等离子体熔融炉、危险废物无害化、资源化技术的研发、制造、测试运行，为客户提供全方位危险废弃物等离子体熔融"零排放"解决方案',
        url: "http://yishunlvse.com/"
      }
    ],
    电子科技: [
      {
        imageSrc:
            "https://www.montage-tech.com/themes/custom/montage2/logo_cn.svg",
        title: "澜起科技(688008.SH)",
        content: "国际领先的数据处理及互连芯片设计公司",
        des:"澜起科技是国际领先的数据处理及互连芯片设计公司，为科创板首批上市企业。",
        url: "https://www.montage-tech.com/cn/home"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThKkTJ.png",
        title: "佳驰科技",
        content: "国内知名的电磁功能材料与结构提供商",
        des:"公司专注于电磁辐射功能材料与结构的设计、测试、分析及制造。2018年获国家科技进步二等奖、2017年荣获四川省科学技术进步一等奖。",
        url: "http://www.cdjc.com.cn/"
      },
      {
        imageSrc:
            "https://www.aubo-robotics.cn/assets/aubo/img/gsIcon.png",
        title: "遨博机器人",
        content: "协作机器人整体解决方案提供商",
        des:"遨博机器人为协作机器人整体解决方案提供商，首席科学家为倪光南院士，其具有核心知识产权的协作机器人产品，已实现核心部件国产化，打破了国外长期垄断。",
        url: "https://www.aubo-robotics.cn/"
      },
      {
        imageSrc:
            "https://www.leezon.net/uploads/news/20200301191002674600.jpg",
        title: "木蚁机器人",
        content: "智慧物流仓储自主移动机器人专家",
        des:"木蚁机器人进行自主移动机器人的研发和生产，推出人机交互、多机器人调度等工业级闭环场景应用解决方案，助力智慧物流仓储。",
        url: "http://www.mooe-robot.com/#"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAM3F.png",
        title: "苍穹数码",
        content: "地理信息全产业链服务提供商",
        des:"长期专注于国产自主可控地理信息平台的研制与应用开发，业内率先打通地理信息全产业链的平台级产品、技术与服务提供商。",
        url: "http://www.kqgeo.com/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAQc4.jpg",
        title: "启元气体",
        content: "半导体级单体稀有气体提供商",
        des:"公司依托技术积累开发出了一系列国产化电子级气体设备，拥有具备自主知识产权的稀有气体精炼设备氪氙及氖氦设备。",
        url: "http://shqiyuangas.com/"
      },
      {
        imageSrc:
            "https://cv2x.com.cn/static/img/logo.af4ca32.png",
        title: "车路通",
        content: "智慧交通及智能网联汽车解决方案提供商",
        des:"深入挖掘车端客户和路端客户的应用需求，打造基于5G、V2X、高精定位、MEC边缘运算等技术为核心的系统化解决方案。",
        url: "https://cv2x.com.cn/"
      },
      {
        imageSrc:
            "https://s1.ax1x.com/2022/03/26/qdABOf.png",
        title: "爱姆欧",
        content: "集成电路MO源生产商",
        des:"爱姆欧专业生产拥有自主知识产权的高纯金属有机化合物（MO源）以及聚烯烃/合成橡胶行业专用烷基铝催化剂，打破了国外公司几十年来在该领域的垄断。",
        url: "http://www.js-mo.com"
      },
      {
        imageSrc:
        // "static/img/logo_wpkj.png",
            'static/img/logo_wpkj.png',
        title: "威频科技",
        content: "高端通用测量仪器仪表研发",
        des:"威频科技核心产品为高端通用射频测试仪表系列，包括模拟信号源、矢量信号源、捷变频信号源、频谱仪等系列化具有核心自主知识产权的通用测试分析仪器仪表，仪表产品广泛应用于民用通信、汽车电子、半导体及航空航天领域。",
        url: "http://www.wpmicrowave.com/"
      },
      {
        imageSrc:
            "http://splchip.com/themes/simplebootx/Public/images/logo.jpg",
        title: "智微电子",
        content: "提供物联网集成电路解决方案",
        des:"智微电子致力于物联网领域集成电路的设计、软件开发和整体解决方案提供，产品广泛应用在智慧能源、智能家居、工业物联网等行业。",
        url: "http://splchip.com/"
      },
    ],
    医疗大健康:[
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAnhT.png",
        title: "科望生物",
        content: "专注于肿瘤免疫治疗的生物医药公司",
        des:"科望生物医药科技有限公司是一家专注于肿瘤免疫治疗的生物医药公司，管线覆盖广泛，重点布局于固有免疫和肿瘤微环境调控。",
        url: "https://www.elpiscience.com/cn/"
      },
      {
        imageSrc:
            "https://www.chima.org.cn/Json/Commons/ImgUrl?url=http://mmbiz.qpic.cn/mmbiz_png/sRFbqwsjVziaibyngQMUtPHTtbzTqFq3dO8xHSts6mZGytVGavg598ND9CrsicLibQhict4ar9jwDMRN7xOicw5hv8RA/0?wx_fmt=png",
        title: "联新医疗",
        content: "智慧医疗整体解决方案提供商",
        des:"深圳市联新移动医疗科技有限公司是一家贴紧临床，为医生、护士、患者和医院管理提供智能化产品和服务，助力建设智慧医院。",
        url: "http://www.lachesis-mh.cn/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThAGH1.jpg",
        title: "中慧元通",
        content: "人用创新型疫苗的供应商",
        des:"中慧元通致力于人用创新型疫苗的研发、注册申报及产业化生产和销售，瞄准国际水准，替代进口，实现出口。",
        url: "http://www.abbbio.com/"
      },
      {
        imageSrc:
            "https://s4.ax1x.com/2021/12/31/ThA8BR.jpg",
        title: "世纪康泰",
        content: "人工晶状体研发及生产商",
        des:"世纪康泰为集研发、生产和销售为一体的三类医疗器械生产企业，公司主要产品为眼科材料人工晶体系列产品及生物材料胶原蛋白海绵系列产品。",
        url: "http://www.tjsjkt.com/tjsjkt/"
      },
      {
        imageSrc:
            "http://www.kairuibiotech.com/uploadfile/20190311/15522594594759626.png",
        title: "恺瑞生物",
        content: "无血清细胞培养基提供商",
        des:"恺瑞生物以无血清细胞培养技术为主导，重点解决蛋白药及疫苗生产、干细胞及免疫细胞治疗以及新药研发等方面的细胞培养问题，协助企业和研发机构提高效率、降低风险及节省费用。 ",
        url: "http://www.kairuibiotech.com/"
      },
    ],
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container id="a_portfolio">
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url}  target="_blank" rel="noreferrer noopener" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer>
                    <ImageContainer imageSrc={card.imageSrc}>

                    </ImageContainer>
                    {/* 删去了Rating页面
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer> */}
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      {/* <CardButton>税收</CardButton> */}
                      <span>{card.des}</span>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    {/* <CardPrice>{card.price}</CardPrice> */}
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      {/*<DecoratorBlob1 />*/}
      {/*<DecoratorBlob2 />*/}
    </Container>
  );
};
